/* You can add global styles to this file, and also import other style files */
@import './variables';
@import './fonts';
@import './table';
@import './login-form';
@import './ng-select';

html {
  font-size: $base-font-size;
  overflow: hidden;

  margin: 0;
  padding: 0;

  height: 100%;
}

body {
  color: $black;
  line-height: 1;
  overflow: hidden;
  font-family: EYInterstateRegular;

  height: 100%;
}

app-root {
  height: 100%;
  display: block;
  width: 100%;
}

input,
.form-control {
  border: 3px solid #fff;
  background: #fff;
  color: $black;
  border-radius: 0;
}

input:disabled,
.form-control:disabled {
  background: $input-disabled-bg-color;
  border-color: $input-disabled-bg-color;
}

input.whiter-placeholder::placeholder {
  color: $placeHolderColor !important;
}

.has-error {
  border-color: $errorColor !important;
}

.has-error-bold {
  border: 2px solid $errorColor !important;
}

input:focus,
.form-control:focus {
  border-color: #ffe600 !important;
  box-shadow: none !important;
  outline: none !important;
}

.form-control {
  padding: 0.5rem;
  font-size: 1rem;
  height: auto;
}

.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: EYInterstateBold;
}

.header {
  height: $headerHeight;
  background: $darkGrey;
}

.content {
  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.with-footer {
    height: $contentWithFooterHeight;
  }

  &.with-footer.mobile {
    height: $contentWithMobileFooterHeight;
  }
}

.footer {
  position: relative;
  width: 100vw;
  bottom: 0;
  height: $footerHeight;
  background: $darkGrey;
  padding-left: 2.25rem;
  padding-right: 2.25rem;

  &.mobile {
    height: $mobileFooterHeight;
    padding-left: 0;
    padding-right: 0;
  }
}

.modal {
  text-align: center;
  padding: 0 !important;
  animation-duration: 0.3s !important;
}

.modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal.mobile {
  overflow: hidden;
  position: absolute;
  height: 100%;

  .modal-dialog {
    max-width: 100vw;
    height: 100%;
    width: 100vw;
    margin: 0;
    top: 0;
    position: absolute;
    left: 0;

    .modal-header {
      position: fixed !important;
      width: 100%;
      top: 0;
    }

    .modal-content {
      height: 100%;
      display: flex;
      justify-content: center;

      .modal-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
      }
    }
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 30rem;
  width: 85vw;
}

.modal-content {
  border-radius: 0;
  color: $black;
  background: $modalBaseColor;
}

.modal-header,
.modal-footer {
  border: 0;
}

.modal-body {
  flex-direction: column;
  text-align: center;
  padding: 0 4.5rem;
}

.modal-footer {
  font-family: EYInterstateBold;
  padding-top: 0;
  padding-bottom: 3rem;
  justify-content: space-between;
  flex-wrap: nowrap;

  .modal-button-link {
    cursor: pointer;

    &.cancel:hover {
      background: #fafafa;
    }
  }
}

.modal-header {
  padding: 30px 30px 0;
  justify-content: flex-end;
  opacity: 0.7;

  .close-icon {
    padding: 0;
    cursor: pointer;

    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.dark-modal {
  .modal-content {
    background: $black;
    color: white;
  }

  .modal-footer {
    .modal-button-link.cancel:hover {
      background: #3f3f3f;
    }
  }
}

.admin-reservation-modal {
  .modal-dialog {
    max-width: 50rem;
  }
}

.edit-item-modal {
  .modal-dialog {
    max-width: 50rem;
  }
}

.invalidate-modal {
  .modal-dialog {
    max-width: 25rem;
  }
}

.date-picker-modal {
  .modal-dialog {
    max-width: 45rem;
  }
}

@media all and (max-width: $mediumFontSizeLimit) {
  html {
    font-size: 12px;
  }
}

@media all and (max-height: $mediumFontSizeLimit) {
  html {
    font-size: 12px;
  }
}

@media all and (max-width: $lowFontSizeLimit) {
  html {
    font-size: 10px;
  }
}

@media all and (max-height: $lowFontSizeLimit) {
  html {
    font-size: 10px;
  }
}


.hover-bigger,
.hover-bigger-less {
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.1s;
}

.hover-bigger-small {
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.5s;
}

.hover-bigger:hover {
  transform: scale(1.2);
}

.hover-bigger-less:hover {
  transform: scale(1.1);
}

.hover-bigger-small:hover {
  transform: scale(1.05);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.inverted-image {
  filter: invert(1);
}

/* scrollbar css */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}

::-webkit-scrollbar-thumb {
  background-color: $mediumGrey;
  outline: 1px solid slategrey;
}

.yellow-bg {
  background: $yellow;
  color: $black;

  &:hover {
    background: #e9d200;
  }
}

.cian-blue-bg {
  background: $cianBlue;
  color: white;

  &:hover {
    background: #54cad2;
  }
}

.red-bg {
  background: $red;
  color: white;

  &:hover {
    background: #ff6f65;
  }
}

.green-bg {
  background: $darkGreen;
  color: white;

  &:hover {
    background: #5ab855;
  }
}

.add-icon {
  padding-left: 15px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .add-icon-div:hover .add-text {
    background: $blackHoverColor;
  }

  img {
    cursor: pointer;
    width: 3.7rem;
    height: 3.5rem;
  }

  .add-text {
    font-family: EYInterstateBold;
    padding-top: 1.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3.5rem;
    float: right;
    text-align: center;
    margin-right: 1.2rem;

    cursor: pointer;
    background: $lightBlack;
    border: none;
  }
}

.parking-slot-text {
  font-family: EYInterstateBold;

  &.parked-slot-text {
    .floor-number,
    .parking-place,
    .number-content {
      color: $darkGreen;
    }
  }

  .number-circle {
    background: white;
    border-radius: 50%;
    display: inline-block;

    .number-content {
      color: $black;
      padding-bottom: 0.125rem;
      display: inline-block;
    }
  }
}

.park-place-modal-title {
  position: absolute;
  top: -2rem;
  left: 1rem;
  color: $cianBlue;

  .park-place-floor {
    font-size: 2rem;
  }

  .park-place-slot {
    font-size: 4.8rem;
  }
}

.disabled-link {
  opacity: 0.6;
}

.app-close-icon {
  padding: 0;
  cursor: pointer;

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.un-bordered-dark-input {
  border: 0;
  border-bottom: 1px solid #707070;
  background: transparent;
  box-shadow: none;
  padding: 10px;
  width: 100%;
  text-align: center;
  color: white;
}

.link-hover {
  &:hover {
    opacity: 0.7;
  }
}

bs-tooltip-container {
  .tooltip-inner {
    background-color: black;
    color: #fff;
    font-size: calculate-rem(14px);
    width: 500px;
    font-family: EYInterstateLight;
  }

  .tooltip-arrow {
    display: none !important;
  }
}

.flag-icon-list-tooltip {
  .tooltip-inner {
    background-color: $yellow;
    color: #fff;
    width: fit-content;

    max-width: 100% !important;
  }

  .tooltip-arrow {
    display: unset !important;

    &::before {
      border-top-color: $yellow;
    }
  }
}

.c-pointer {
  cursor: pointer;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

// tmp fix for modal backdrop too high z-index (marked as ng-bootstrap bug)
/* stylelint-disable-next-line selector-type-no-unknown */
ngb-modal-backdrop {
  z-index: 1040 !important;
}

@import 'theme/variables';

$loginFormPadding: 1.75rem;
$loginFormMaxHeight: 17rem;
$loginFormMaxWidth: 26.5rem;

$loginPlusPasswordResetHeight: 5rem;

$titleFontSize: 2rem;
$titleMarginBottom: 1.75rem;
$loginBottomTriangleHeight: 5rem;

$iconMarginRight: calculate-rem(13px);

.login-form-container {
  overflow: hidden;
  width: 100vw;
  animation-duration: 0.5s !important;

  .login-logo {
    display: block;
    text-align: center;

    img {
      height: 13.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .login-form {
    margin: auto;
    padding: $loginFormPadding;
    background-color: $yellow;
    height: $loginFormMaxHeight;
    max-width: $loginFormMaxWidth;
    position: relative;

    input,
    input:focus {
      background-color: $yellow !important;
      border: 1px solid $black !important;
      color: $black !important;
    }

    input.has-error {
      border-color: $errorColor !important;
    }

    input.required {
      border-color: rgb(150 178 255 / 40%) !important;
    }

    button {
      background-color: $black;
      border: none;
      border-radius: 0;

      &:disabled {
        background: rgb(0 0 20 / 10%);
        color: $black;
      }
    }

    .title {
      font-size: $titleFontSize;
      font-family: EYInterstateBold;
      margin-bottom: $titleMarginBottom;

      .title-for-admin {
        font-size: 1.4rem;
      }
    }

    .sub-title {
      flex: 1;
      position: relative;

      font-size: 1.45rem;
      font-family: EYInterstateBold;
    }

    &.password-reset {
      height: #{$loginFormMaxHeight + $loginPlusPasswordResetHeight};

      .title {
        font-size: #{$titleFontSize - 0.5rem};
        margin-bottom: #{$titleMarginBottom - 1rem};
      }
    }

    .password-reset-logout {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 3rem;
      cursor: pointer;
    }

    .password-reset-info-group {
      position: relative;

      input {
        padding-right: 2.5rem;
      }

      .password-reset-info {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        width: 1.6rem;
        cursor: pointer;
      }
    }
  }

  .login-bottom-triangle {
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $loginBottomTriangleHeight $loginFormMaxWidth 0 0;
    border-color: $yellow transparent transparent transparent;
  }
}

// logo responsive css for different font sizes!
@media all and (max-height: 700px) {
  .login-logo {
    display: none !important;
  }
}

@media all and (max-height: 450px) {
  .login-bottom-triangle {
    display: none !important;
  }
}

@media all and (max-height: 300px) {
  .login-form-container {
    .login-form {
      height: 14rem;

      &.password-reset {
        height: #{14rem + $loginPlusPasswordResetHeight};
      }

      .title {
        display: none;
      }
    }
  }
}

.password-tooltip {
  text-align: center;
  padding: 2rem;
  font-family: EYInterstateBold;

  img {
    width: 3rem;
    padding-bottom: 2rem;
  }
}

.reset-password {
  cursor: pointer;
  color: $lightBlack;
  transition: 0.1s all ease-in-out;

  &:hover {
    color: $solidGrey;
  }
}

.sub-title-back {
  margin-right: $iconMarginRight;
  width: calculate-rem(48px);
  cursor: pointer;
}

.password-reset-icon {
  margin-right: $iconMarginRight;
  width: calculate-rem(48px);
}

.password-reset-message {
  font-family: EYInterstateLight;
  font-size: 1rem;
}


@import 'theme/variables';

$bg-no-repeat: no-repeat;
$bg-size: calculate-rem(14px) calculate-rem(14px);
$margin-top: calculate-rem(2px);
$border-radius: 0 !important;
$select-arrow-left-margin: calculate-rem(7px);
$ng-select-box-shadow: none !important;
$ng-select-font-size: 1.2rem !important;
$ng-select-border: none !important;
$ng-select-height: calculate-rem(45px);

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: $ng-select-box-shadow;
}

.ng-select .ng-select-container:hover {
  box-shadow: $ng-select-box-shadow;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  display: flex;
  justify-content: center;
  margin-top: calculate-rem(6px) !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: normal !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: $input-disabled-bg-color;
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: calculate-rem(26px) !important;
}

.ng-select-container .ng-value-container {
  display: flex;
  justify-content: center;
}

.ng-select.ng-select-single .ng-select-container {
  font-size: $ng-select-font-size;
  border-radius: $border-radius;
  border: $ng-select-border;
  height: $ng-select-height;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-width: 0 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: $ng-select-font-size;
  border-radius: $border-radius;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  width: 100% !important;
  background: $veryLightGrey !important;
  color: $cianBlue !important;
}

.ng-dropdown-panel.ng-select-bottom {
  border: $ng-select-border;
  border-radius: $border-radius;
}

.ng-select.ng-select-opened .ng-arrow-wrapper {
  margin-left: $select-arrow-left-margin;
  background-image: url('/assets/images/arrow_up.svg') !important;
  background-repeat: $bg-no-repeat;
  background-size: $bg-size;
  margin-top: $margin-top;
}

.ng-select .ng-arrow-wrapper {
  margin-left: $select-arrow-left-margin;
  background-image: url('/assets/images/arrow_down.svg') !important;
  background-repeat: $bg-no-repeat;
  background-size: $bg-size;
  margin-top: $margin-top;
}

@use 'sass:math';

/* You can add global styles to this file, and also import other style files */
$base-font-size: 14px;

$footerHeight: 8.5rem;
$headerHeight: 4.6rem;

$smallFooterHeight: 6rem;

$contentWithFooterHeight: calc(100% - #{$footerHeight});
$contentWithHeaderHeight: calc(100% - #{$headerHeight});

// responsive
$lowFontSizeLimit: 360px;
$mediumFontSizeLimit: 450px;

// colors
$yellow: #ffe600;
$darkGrey: #333;
$mediumGrey: #808080; // background
$lightGrey: #cecece;
$black: #333;
$lightBlack: #434343;
$solidGrey: #707070;

$input-disabled-bg-color: #f2f2f2;

$hoverDarkGrey: #706f6f;

$errorColor: rgb(200 20 20 / 40%);
$errorColor1: rgb(200 20 20 / 100%);

$modalBaseColor: #e8e8e8;
$placeHolderColor: #c8c8c8;
$modalGreyColor: $black;

$telephone: #95989a;

$blackHoverColor: #505050;

$lightGreen: #88ae85;
$darkGreen: #33922e;
$orange: #fd7e14;
$red: #e83e33;
$white: $modalBaseColor;

$customGrey: #9d9d9c;
$veryLightGrey: #eee;

$cianBlue: #00a3ae;

// map colors:
$privateColor: #dad073;
$visitorColor: #d18c88;

// COMMON VALUES
$floorSelectWidth: 4.3rem;

// MOBILE VARIABLES
$mobileFooterHeight: 6rem;
$contentWithMobileFooterHeight: calc(100% - #{$mobileFooterHeight});
$contentWithMobileHeaderHeight: calc(100% - #{$headerHeight});

@function calculate-rem($size) {
  $rem-size: math.div($size, $base-font-size);
  @return #{$rem-size}rem;
}
